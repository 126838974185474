import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import AccountCheckboxList from "../../data_accounts";
import CampaignCheckboxList from "../../data_campaigns";
import Box from "@mui/material/Box";
import MDTypography from "../../../../components/MDTypography";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { useFilteredData, useTableDataAdsetLogs } from "../../adsets_logs";
import TextField from "@mui/material/TextField";
import { getCurrentTimeDifference } from "../../../../CUSTOM_COMPONENTS/time_functions";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";
import { processRules, userNames, ruleTips3, budgetROITips } from "../constants";
import DialogFormRule from "../rule_dialog";
import { processRulesCombined } from "../stop_loss_combined_2025_02_27/constants";
import DialogFormRuleCombined from "../stop_loss_combined_2025_02_27/dialog_form";

function StopLossCombined_TT_2025_03_12() {
  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [tableData1, setTableData1] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "10%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "58%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "30%" },
      ...(userRole === "admin"
        ? [{ Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" }]
        : []),
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [tempValue1, setTempValue1] = useState("");
  const [tempValue2, setTempValue2] = useState("");
  const [tempValue3, setTempValue3] = useState("");
  const [tempValue4, setTempValue4] = useState("");
  const [tempValue5, setTempValue5] = useState("");
  const [tempValue6, setTempValue6] = useState("");
  const [tempNotifyOnly, setTempNotifyOnly] = useState("");
  const [tempActionOnly, setTempActionOnly] = useState("");
  const [tempAction1Value, setTempAction1Value] = useState("");
  const [tempAction2Value, setTempAction2Value] = useState("");

  const [globalRuleStatus, setGlobalRuleStatus] = useState(false);
  const [firstSetActivationHourProvider, setFirstSetActivationHourProvider] = useState("");

  const tableDataAdsetLogs = useTableDataAdsetLogs("stop_loss_tt");
  const filteredDataLogs = useFilteredData(tableDataAdsetLogs, search);

  const API_URL = "https://dashboard-1049948018753.us-central1.run.app";
  const userName = userNames[userEmail];

  const ruleTips = ["Set the first activation time in the 24-hour format (provider time)."];

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/rules/stop_loss_combined_TT_2025_03_12/`);

      const rows = processRulesCombined(
        response.data,
        userRole,
        userName,
        handleToggle,
        setFirstSetActivationHourProvider,
        setGlobalRuleStatus,
        handleEditClick
      );

      setTableData1((prevState) => ({ ...prevState, rows: rows }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleToggle = useCallback(async (ruleName = "", columnName, currentStatus) => {
    const newStatus = currentStatus === true ? "Inactive" : "Active";
    console.log("Current status/new status:", currentStatus, newStatus);

    // Send request to update rule status
    const url = `${API_URL}/api/rules_stop_loss_combined_TT_2025_03_12/update_status/`;

    try {
      console.log("rule_name/columnName/newStatus:", ruleName, columnName, newStatus);
      await axios.post(url, {
        rule_name: ruleName,
        column_name: columnName,
        status: newStatus,
        user_email: userEmail,
      });
      setTableData1((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_name === ruleName ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      await fetchData();
    } catch (error) {
      toast.error(`Failed to update rule status for ID ${ruleName}.`);
    }
  }, []);

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);

    setTempValue1(row.value1);
    setTempValue2(row.value2);
    setTempValue3(row.value3);
    setTempValue4(row.value4);
    setTempValue5(row.value5);
    setTempValue6(row.value6);
    setTempNotifyOnly(row.notify_only);
    setTempActionOnly(row.action_only);
    setTempAction1Value(row.action_value1);
    setTempAction2Value(row.action_value2);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);

    setTempValue1("");
    setTempValue2("");
    setTempValue3("");
    setTempValue4("");
    setTempValue5("");
    setTempValue6("");
    setTempNotifyOnly("");
    setTempActionOnly("");
    setTempAction1Value("");
    setTempAction2Value("");
  };

  const handleSave = async () => {
    if (!selectedRow) return;
    const updatedData = {
      rule_name: selectedRow.rule_name,
      value1: tempValue1,
      value2: tempValue2,
      value3: tempValue3,
      value4: tempValue4,
      value5: tempValue5,
      action_value1: tempAction1Value,
      action_value2: tempAction2Value,
      action_only: tempActionOnly,
      notify_only: tempNotifyOnly,
      user_email: userEmail,
    };
    try {
      await axios.post(
        `${API_URL}/api/rules/update_rule_rules_stop_loss_combined_TT_2025_03_12/`,
        updatedData
      );
      await fetchData();
      toast.success("Data updated successfully!");
      handleEditDialogClose(); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleValueChange = (field, value) => {
    switch (field) {
      case "setValue1":
        setTempValue1(value);
        break;
      case "setValue2":
        setTempValue2(value);
        break;
      case "setValue3":
        setTempValue3(value);
        break;
      case "setValue4":
        setTempValue4(value);
        break;
      case "setValue5":
        setTempValue5(value);
        break;
      case "setNotifyOnly":
        setTempNotifyOnly(value);
        break;
      case "setActionOnly":
        setTempActionOnly(value);
        break;
      case "setAction1Value":
        setTempAction1Value(value);
        break;
      case "setAction2Value":
        setTempAction2Value(value);
        break;
      default:
        break;
    }
  };

  const handleSubmitForm = async () => {
    const data = {
      first_set_activation_hour: firstSetActivationHourProvider,
    };

    try {
      const response = await axios.post(
        `${API_URL}/api/rules/update_activation_stop_loss_combined_TT_2025_03_12/`,
        data
      );
      console.log("Response:", response.data);
      toast.success(`Success!`);
      await fetchData();
    } catch (error) {
      console.error("Error updating rule activation hour 1:", error);
      toast.error(`Failed to update hour`);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={globalRuleStatus}
                  onChange={() => {
                    setGlobalRuleStatus(!globalRuleStatus);
                    handleToggle("stop_loss_combined", "global_rule_status", globalRuleStatus);
                  }}
                  disabled={userRole !== "admin"}
                />
              }
              label={`stop_loss_combined - ${globalRuleStatus ? "Active" : "Paused"}`}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Activation / Deactivation</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips)}
              </MDBox>
            </MDBox>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1} ml={2}>
              <span
                style={{
                  display: "list-item",
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                Rule first activation hour:&nbsp;&nbsp;
              </span>
              <TextField
                value={firstSetActivationHourProvider}
                onChange={(e) => setFirstSetActivationHourProvider(e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "4%" }}
                disabled={userRole !== "admin"}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                :20&nbsp;&nbsp;
              </span>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1}>
              {userRole && userRole === "admin" && (
                <Button
                  onClick={handleSubmitForm}
                  startIcon={<SaveIcon />}
                  style={{
                    marginLeft: "-20px",
                    color: "black",
                  }}
                >
                  Save
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData1} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <AccountCheckboxList
                ruleName="stop_loss_tt"
                fetchApiEndpoint={`${API_URL}/api/rules/get_tt_accounts_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <CampaignCheckboxList
                ruleName="stop_loss_tt"
                fetchApiEndpoint={`${API_URL}/api/rules/get_tt_campaigns_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableDataAdsetLogs} canSearch entriesPerPage={false} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/*Edit Dialog */}
      <DialogFormRuleCombined
        openEditDialog={openEditDialog}
        handleEditDialogClose={handleEditDialogClose}
        handleSave={handleSave}
        selectedRow={selectedRow}
        userRole={userRole}
        userName={userName}
        handleValueChange={handleValueChange}
        tempValue1={tempValue1}
        tempValue2={tempValue2}
        tempValue3={tempValue3}
        tempValue4={tempValue4}
        tempValue5={tempValue5}
        tempAction1Value={tempAction1Value}
        tempAction2Value={tempAction2Value}
        tempNotifyOnly={tempNotifyOnly}
        tempActionOnly={tempActionOnly}
        Tips={renderTips(budgetROITips)}
      />
    </DashboardLayout>
  );
}

export default StopLossCombined_TT_2025_03_12;
