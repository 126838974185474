import { API_URL_BACKEND } from "../constants_global";

export async function addCategoryIfNew(categories, newCategory) {
  const formattedCategory = newCategory
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  if (!categories.includes(formattedCategory)) {
    console.log(`Adding new category: ${formattedCategory}`);
    fetch(`${API_URL_BACKEND}/add_new_category/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ new_category: formattedCategory }),
    }).catch((error) => console.error("Error adding category:", error));
  } else {
    console.log(`Category already exists: ${formattedCategory}`);
  }
}
