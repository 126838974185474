import { Button, CircularProgress } from "@mui/material";
import { API_URL_GET_DATA } from "../tt_constants";
import PropTypes from "prop-types";
import { useState } from "react";

// Функция для генерации ссылки
export const handleGenerateLink = async (inputFbCampaignId, selectedProvider, setInputLinkUrl) => {
  if (!inputFbCampaignId) {
    alert("Please enter the FB Campaign ID.");
    return;
  } else if (!selectedProvider) {
    alert("Please select a provider.");
    return;
  }

  try {
    const response = await fetch(`${API_URL_GET_DATA}/get_fb_link_and_transform_to_tt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ campaign_id: inputFbCampaignId.trim(), provider: selectedProvider }),
    });

    const data = await response.json();
    setInputLinkUrl(data);
  } catch (error) {
    console.error("Error fetching transformed link:", error);
  }
};

export const GenerateTTLinkFromFBButton = ({
  inputFbCampaignId,
  selectedProvider,
  setInputLinkUrl,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await handleGenerateLink(inputFbCampaignId, selectedProvider, setInputLinkUrl);
    setLoading(false);
  };

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#090909",
        color: "white",
        "&:hover": {
          backgroundColor: "darkgray",
        },
      }}
      onClick={handleClick}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress size={24} sx={{ color: "white" }} />
      ) : (
        "Generate TT Link from FB"
      )}
    </Button>
  );
};

GenerateTTLinkFromFBButton.propTypes = {
  inputFbCampaignId: PropTypes.string.isRequired,
  selectedProvider: PropTypes.string.isRequired,
  setInputLinkUrl: PropTypes.func.isRequired,
};
