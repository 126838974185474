import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "CUSTOM_COMPONENTS/custom_dropdown";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomInputField from "CUSTOM_COMPONENTS/custom_input_field";
import CustomInputFieldMultiline from "CUSTOM_COMPONENTS/custom_input_field_multiline";
import CustomNumericInputField from "CUSTOM_COMPONENTS/custom_numeric_input_field";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import MDInput from "../../../components/MDInput";
import Button from "@mui/material/Button";
import { emailsNames, geoAll, languagesAll, Tips_for_all } from "../constants";
import { renderTips } from "../../../CUSTOM_COMPONENTS/render_TIPS";
import DialogFormGenerateTargeting from "../targeting_dialog";
import CustomDropdownInput from "../../../CUSTOM_COMPONENTS/custom_dropdown_input";
import { convertPropertiesToCSV, convertToCSV, createCSVDownloadLink } from "./convert_to_csv";
import { addCategoryIfNew } from "../chech_if_new_category";
import { API_URL_CAMPAIGN_CREATION, API_URL_BACKEND } from "../../constants_global";
import CustomCheckbox from "../../../CUSTOM_COMPONENTS/custom_checkbox";
import { addCityToName, Tips } from "../if_city";

function CreateCampaign() {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [categoriesAll, setCategoriesAll] = useState([]);
  const [accountsAll, setAccountsAll] = useState([]);
  const [selectedPage, setSelectedPage] = useState("");
  const [pagesAll, setPagesAll] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const typesAll = ["CBO", "ABO", "ABO | BID_CAP", "ABO | COST_CAP", "ADVANTAGE_PLUS"];
  const [selectedStatus, setSelectedStatus] = useState("");
  const statusesAll = ["ACTIVE", "PAUSED"];

  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const defaultDate = tomorrow.toISOString().split("T")[0]; // 'YYYY-MM-DD' format

  const [date, setDate] = useState(defaultDate);
  const [time, setTime] = useState("00:00:00");

  const [selectedGeo, setSelectedGeo] = useState("");

  const [campaignsData, setCampaignsData] = useState({});
  const [selectedName, setSelectedName] = useState("");
  const [namesAll, setNamesAll] = useState([]);

  const [selectedFbSpCategory, setSelectedFbSpCategory] = useState("");
  const fbSpCategoriesAll = ["EMPLOYMENT", "HOUSING", "FINANCIAL_PRODUCTS_SERVICES", "NONE"];

  const [selectedStage, setSelectedStage] = useState("");
  const [stagesAll, setStagesAll] = useState([]);
  const [selectedOptimEvent, setSelectedOptimEvent] = useState("");
  const [optimEventsAll, setOptimEventsAll] = useState([]);
  const [selectedFbPixel, setSelectedFbPixel] = useState("");
  const [fbPixelsAll, setFbPixelsAll] = useState([]);

  const [inputRFolderNameGD, setInputRFolderNameGD] = useState("");
  const [inputLinkForFB, setInputLinkForFB] = useState("");
  const [inputCampNameComment, setCampNameComment] = useState("");

  const [inputAdjAdCopies, setInputAdjAdCopies] = useState([]);
  const [inputAdjAdCopy, setInputAdjAdCopy] = useState("");

  const [inputDescription, setInputDescription] = useState("");
  const [inputAdjAdText, setInputAdjAdText] = useState("");
  const [inputDCategory, setInputDCategory] = useState("");

  const [inputAdsPerAdset, setInputAdsPerAdset] = useState("");
  const [inputBudget, setInputBudget] = useState("");
  const [inputBidAmount, setInputBidAmount] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [selectedDealId, setSelectedDealID] = useState("");
  const currentDate = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");
  const [isCity, setIsCity] = useState(false);

  const campaignNameView = inputCampNameComment
    ? `${currentDate} - ${
        selectedAccount.split(": ")[0]
      } - ${inputDCategory} - ${selectedType} - ${selectedStage} - ${selectedGeo} - ${selectedLanguage} | ${inputCampNameComment}${
        isCity ? " city" : ""
      }`
    : `${currentDate} - ${
        selectedAccount.split(": ")[0]
      } - ${inputDCategory} - ${selectedType} - ${selectedStage} - ${selectedGeo} - ${selectedLanguage}${
        isCity ? " | city" : ""
      }`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accountsResponse = await fetch(`${API_URL_BACKEND}/get_accounts_all/`);
        const accountsData = await accountsResponse.json();
        setAccountsAll(accountsData);

        const pageResponse = await fetch(`${API_URL_BACKEND}/get_pages_all/`);
        const pagesData = await pageResponse.json();
        setPagesAll(pagesData);

        const categoryResponse = await fetch(`${API_URL_BACKEND}/get_categories_all/`);
        const categoriesData = await categoryResponse.json();
        setCategoriesAll(categoriesData);

        const nameResponse = await fetch(`${API_URL_BACKEND}/get_campaigns_fb_ready_to_launch/`);
        const namesData = await nameResponse.json();
        setCampaignsData(namesData);

        let filteredCampaignNames;

        if ((userRole === "admin" && namesData) || userEmail === "asyapuh1618@gmail.com") {
          filteredCampaignNames = Object.keys(namesData);
        } else if (userRole !== "admin" && namesData) {
          const userName = emailsNames[userEmail];
          console.log(`User Name:`, userName);
          filteredCampaignNames = Object.keys(namesData).filter((campaignName) =>
            campaignName.includes(userName)
          );
          console.log(`Filtered Campaign Names:`, filteredCampaignNames);
        }

        setNamesAll(filteredCampaignNames);

        const campaignsConstantsAll = await fetch(
          `${API_URL_BACKEND}/get_constants_fb_ready_to_launch/`
        );
        const campaignsConstants = await campaignsConstantsAll.json();
        setStagesAll(campaignsConstants["d___campaign_stages"]);
        setOptimEventsAll(campaignsConstants["fb___optimisation_events"]);
        setFbPixelsAll(campaignsConstants["fb___pixels"]);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedName) {
      const campaignProperties = campaignsData[selectedName];
      console.log(`Campaign data:`, campaignProperties);
      if (campaignProperties) {
        setSelectedLanguage(campaignProperties["language"] || "");

        const adAccountId = campaignProperties["ad_account"] || "";
        const matchedAccount = accountsAll.find((account) => {
          const accountParts = account.split(": ");
          return accountParts[1] === adAccountId;
        });
        setSelectedAccount(matchedAccount || "");

        const fbPage = campaignProperties["fb___page"] || "";
        const matchedPage = pagesAll.find((page) => {
          const pageParts = page.split(": ");
          return pageParts[1] === fbPage;
        });
        setSelectedPage(matchedPage || "");

        if (campaignProperties) {
          setSelectedType(campaignProperties["d___campaign_type"] || "");
          setSelectedStatus("ACTIVE");
          setSelectedGeo(campaignProperties["fb___geo"] || "");
          setInputRFolderNameGD(campaignProperties["r___folder_name__google_drive_"] || "");
          setInputLinkForFB(campaignProperties["fb__link_for_fb"] || "");
          setInputAdjAdCopy(campaignProperties["d___adjusted_ad_copy"]);
          inputAdjAdCopies[0] = campaignProperties["d___adjusted_ad_copy"];
          setInputAdjAdText(campaignProperties["adjusted_ad_text"] || "");
          setSelectedFbSpCategory(campaignProperties["fb___special_category"] || "");
          setSelectedFbPixel(campaignProperties["fb___pixel"] || "");
          setSelectedOptimEvent(campaignProperties["fb___optimisation_event"] || "");
          setSelectedStage(campaignProperties["d___campaign_stage"] || "");
          setInputDCategory(campaignProperties["d___category"] || "");
          setSelectedDealID(campaignProperties["hs_object_id"] || "");
        }
      }
    }
  }, [selectedName, campaignsData]);

  const handleCreateCampaign = async () => {
    if (
      !selectedAccount ||
      !selectedLanguage ||
      !selectedGeo ||
      !selectedPage ||
      !inputRFolderNameGD ||
      !inputAdjAdCopy ||
      !inputDescription ||
      !inputAdjAdText ||
      !selectedFbPixel ||
      !selectedOptimEvent ||
      !selectedStage ||
      !inputDCategory ||
      !selectedType ||
      !selectedStatus ||
      !inputLinkForFB
    ) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    addCategoryIfNew(categoriesAll, inputDCategory);
    fetch(`${API_URL_CAMPAIGN_CREATION}/campaign-create/`, {
      // fetch(`${API_URL_CAMPAIGN_CREATION}/campaign-create-test-response/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          schedule_date: date,
          schedule_time: time,
          ad_account: selectedAccount,
          adjusted_ad_text: inputAdjAdText,
          language: selectedLanguage,
          fb___page: selectedPage,
          d___campaign_type: selectedType,
          adset_status: selectedStatus,
          fb___geo: selectedGeo,
          name: campaignNameView,
          comment: await addCityToName({ isCity, nameComment: inputCampNameComment }),
          r___folder_name__google_drive_: inputRFolderNameGD,
          fb__link_for_fb: inputLinkForFB,
          d___adjusted_ad_copy: inputAdjAdCopy,
          primary_texts: inputAdjAdCopies,
          fb___special_category: selectedFbSpCategory,
          fb___pixel: selectedFbPixel,
          fb___optimisation_event: selectedOptimEvent,
          d___campaign_stage: selectedStage,
          d___category: inputDCategory,
          hs_object_id: selectedDealId,
          description: inputDescription,
          ads_per_adset: inputAdsPerAdset,
          budget: inputBudget,
          bid_cost_amount: inputBidAmount,
          activate_rules: "YES",
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.data || `HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data.data, null, 2));
        const csvContent = convertToCSV(data.data_to_csv); // Конвертируем данные в CSV
        if (csvContent) {
          const downloadUrl = createCSVDownloadLink(csvContent);
          setDownloadUrl(downloadUrl);
        }
        toast.success("Campaign successfully created!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleCreateCampaign}>
            Create campaign
          </button>
        </div>
      </div>
    );
  };

  const handleOpenTargetingDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseTargetingDialog = () => {
    setOpenEditDialog(false);
  };

  const ButtonTargeting = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleOpenTargetingDialog}>
            Custom targeting
          </button>
        </div>
        <DialogFormGenerateTargeting
          openEditDialog={openEditDialog}
          handleEditDialogClose={handleCloseTargetingDialog}
          selectedGeo={selectedGeo}
          setSelectedGeo={setSelectedGeo}
        />
      </div>
    );
  };

  // const ResponseMessage = () => {
  //   return (
  //     <div className="response-message-container">
  //       {isLoading ? (
  //         <div className="loading-spinner">
  //           <CircularProgress color="success" />
  //         </div>
  //       ) : (
  //         <Card className="response-message" sx={{ overflow: "auto" }}>
  //           <pre>{responseMessage}</pre>
  //         </Card>
  //       )}
  //     </div>
  //   );
  // };
  const ResponseMessage = () => {
    return (
      <div
        className="response-message-container"
        style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}
      >
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <>
            {/* Карточка для отображения JSON */}
            <Card
              className="response-message"
              sx={{
                overflow: "auto",
                marginBottom: "16px",
                width: "100%", // Занимает 80% ширины контейнера
                maxWidth: "1000px", // Ограничение максимальной ширины
              }}
            >
              <pre>{responseMessage}</pre>
            </Card>

            {/* Кнопка для скачивания CSV */}
            {downloadUrl && (
              <Button
                variant="contained"
                color="success"
                href={downloadUrl}
                download="properties.csv"
                style={{ marginTop: "16px", marginBottom: "16px" }} // Отступ сверху
              >
                Download CSV
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  const addInputField = () => {
    if (inputAdjAdCopies && inputAdjAdCopies.length < 5) {
      setInputAdjAdCopies([...inputAdjAdCopies, ""]); // добавляем новое пустое поле
    }
  };

  const removeInputField = (index) => {
    const newInputAdjAdCopies = inputAdjAdCopies.filter((_, i) => i !== index);
    setInputAdjAdCopies(newInputAdjAdCopies);
  };

  const handleInputChange = (index, value) => {
    const newInputAdjAdCopies = [...inputAdjAdCopies];
    newInputAdjAdCopies[index] = value; // обновляем конкретное поле
    setInputAdjAdCopies(newInputAdjAdCopies);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3} display="flex" alignItems="center">
                <img
                  src="https://www.svgrepo.com/show/83242/birdhouse.svg"
                  alt="Image"
                  style={{ width: "40px", height: "40px" }}
                />
                <MDTypography variant="h5">&nbsp;&nbsp;Campaigns creation</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Box flexGrow={1}>
                        <CustomDropdownInput
                          selectedValue={selectedName}
                          setSelectedValue={setSelectedName}
                          options={namesAll}
                          label={"Campaign name"}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomDropdown
                          selectedCategory={selectedAccount}
                          setSelectedCategory={setSelectedAccount}
                          categories={accountsAll}
                          category_name={"FB - AD account"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedPage}
                          setSelectedCategory={setSelectedPage}
                          categories={pagesAll}
                          category_name={"FB - page"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedType}
                          setSelectedCategory={setSelectedType}
                          categories={typesAll}
                          category_name={"D - Campaign type"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedLanguage}
                          setSelectedCategory={setSelectedLanguage}
                          categories={languagesAll}
                          category_name={"FB - Language"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedGeo}
                          setSelectedCategory={setSelectedGeo}
                          categories={geoAll}
                          category_name={"FB - Geo"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedFbSpCategory}
                          setSelectedCategory={setSelectedFbSpCategory}
                          categories={fbSpCategoriesAll}
                          category_name={"fb___special_category"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedFbPixel}
                          setSelectedCategory={setSelectedFbPixel}
                          categories={fbPixelsAll}
                          category_name={"fb___pixel"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedOptimEvent}
                          setSelectedCategory={setSelectedOptimEvent}
                          categories={optimEventsAll}
                          category_name={"fb___optimisation_event"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedStage}
                          setSelectedCategory={setSelectedStage}
                          categories={stagesAll}
                          category_name={"d___campaign_stage"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdownInput
                          selectedValue={inputDCategory}
                          setSelectedValue={setInputDCategory}
                          options={categoriesAll}
                          label={"d___category"}
                          required={true}
                        />
                      </Grid>
                      {/*<MDBox pt={2} px={2}>*/}
                      {/*  <ButtonTargeting />*/}
                      {/*</MDBox>*/}
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <MDTypography variant="h6" color="text">
                            Schedule campaign start based on account timezone (or click ⛳️ to
                            activate immediately)
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12} container alignItems="center">
                        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 5 }}>
                          <MDInput type="date" value={date} onChange={handleDateChange} />
                          <MDInput type="time" value={time} onChange={handleTimeChange} />
                          <Button
                            onClick={() => {
                              setDate("");
                              setTime("");
                            }}
                            variant="contained"
                            color="white"
                          >
                            ⛳️ START NOW
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/426161/bird.svg"
                            alt="Image"
                            style={{ width: "30px", height: "30px" }}
                          />
                          <MDTypography variant="h6">
                            &nbsp;&nbsp;FB link and GoogleDrive settings
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={inputRFolderNameGD}
                          setInputValue={setInputRFolderNameGD}
                          label={"GoogleDrive folder_name or link (https://)"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputFieldMultiline
                          inputValue={inputLinkForFB}
                          setInputValue={setInputLinkForFB}
                          label={"fb__link_for_fb"}
                          multiline
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/426161/bird.svg"
                            alt="Image"
                            style={{ width: "28px", height: "28px" }}
                          />
                          <MDTypography variant="h6">
                            &nbsp;&nbsp;Campaign name settings
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {renderTips(Tips)}
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <CustomInputField
                          inputValue={inputCampNameComment}
                          setInputValue={setCampNameComment}
                          label={"Commentary"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <CustomCheckbox
                          selectedValue={isCity}
                          setSelectedValue={setIsCity}
                          label={"IS CITY"}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={campaignNameView}
                          setInputValue={() => {}}
                          label={"Campaign Name View (the date and account name may differ)"}
                          required={false}
                          readOnly={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/426161/bird.svg"
                            alt="Image"
                            style={{ width: "28px", height: "28px" }}
                          />
                          <MDTypography variant="h6">&nbsp;&nbsp;Ad Set settings</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: 1Adset/2Ads for CBO, 1Adset/All_Ads for ABO
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputAdsPerAdset}
                          setInputValue={setInputAdsPerAdset}
                          label={"Ads per adset"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: $5
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputBudget}
                          setInputValue={setInputBudget}
                          label={"Daily budget (in $)"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: $1 (Only for BID/COST_CAP)
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputBidAmount}
                          setInputValue={setInputBidAmount}
                          label={"Bid/Cost amount (in $)"}
                          required={false}
                          allowFloat={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <CustomDropdown
                          selectedCategory={selectedStatus}
                          setSelectedCategory={setSelectedStatus}
                          categories={statusesAll}
                          category_name={"Adset status"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/426161/bird.svg"
                            alt="Image"
                            style={{ width: "28px", height: "28px" }}
                          />
                          <MDTypography variant="h6">&nbsp;&nbsp;Ad settings</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12} container alignItems="center">
                        <Grid item xs={12} md={11}>
                          <CustomInputField
                            inputValue={inputAdjAdText}
                            setInputValue={setInputAdjAdText}
                            label={"Headline"}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={11}>
                          <CustomInputFieldMultiline
                            inputValue={inputDescription}
                            setInputValue={setInputDescription}
                            label={"Ad Description"}
                            multiline
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <CustomInputFieldMultiline
                            inputValue={inputAdjAdCopies[0]}
                            setInputValue={(value) => handleInputChange(0, value)}
                            label={"Ad text"}
                            multiline
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton onClick={() => addInputField(0)}>
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                      {/* Поля для дополнительных текстов */}
                      {inputAdjAdCopies.slice(1).map((inputValue, index) => (
                        <Grid item xs={12} md={12} key={index + 1} container alignItems="center">
                          <Grid item xs={11}>
                            <CustomInputFieldMultiline
                              inputValue={inputValue}
                              setInputValue={(value) => handleInputChange(index + 1, value)} // Индекс увеличен на 1
                              label={`Ad text ${index + 2}`} // Динамическое имя поля
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton onClick={() => addInputField(index + 1)}>
                              <AddIcon />
                            </IconButton>
                            <IconButton onClick={() => removeInputField(index + 1)}>
                              {" "}
                              {/* Передаем текущий индекс + 1 */}
                              <ClearIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>

              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateCampaign;
