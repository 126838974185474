import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

export const ttGeoAll = [
  "AU",
  "AT",
  "AZ",
  "DZ",
  "AR",
  "BD",
  "BH",
  "BY",
  "BE",
  "BG",
  "BO",
  "BR",
  "HU",
  "VN",
  "GT",
  "DE",
  "GR",
  "DK",
  "DO",
  "EG",
  "IL",
  "ID",
  "JO",
  "IQ",
  "IE",
  "ES",
  "IT",
  "KZ",
  "KH",
  "CA",
  "QA",
  "KE",
  "CY",
  "CO",
  "KR",
  "CR",
  "KW",
  "LV",
  "LB",
  "LT",
  "MY",
  "MA",
  "MX",
  "NL",
  "NZ",
  "NO",
  "AE",
  "OM",
  "PK",
  "PA",
  "PY",
  "PE",
  "PL",
  "PT",
  "PR",
  "RO",
  "SA",
  "RS",
  "SG",
  "SK",
  "SI",
  "GB",
  "US",
  "TH",
  "TW",
  "TR",
  "UY",
  "PH",
  "FI",
  "FR",
  "HR",
  "CZ",
  "CL",
  "CH",
  "SE",
  "LK",
  "EC",
  "EE",
  "ZA",
  "JP",
];

export const SectionHeader = ({ text, iconSrc }) => {
  return (
    <Grid item xs={12} md={12}>
      <MDBox mt={3} display="flex" alignItems="center">
        <img
          src={iconSrc || "https://www.svgrepo.com/show/375860/pin.svg"}
          alt="Icon"
          style={{ width: "30px", height: "30px" }}
        />
        <MDTypography variant="h6">&nbsp;&nbsp;{text}</MDTypography>
      </MDBox>
    </Grid>
  );
};

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
  iconSrc: PropTypes.string,
};

SectionHeader.defaultProps = {
  iconSrc: "https://www.svgrepo.com/show/375860/pin.svg",
};

export const Tips = [
  "Schedule created campaign start (time in UTC — it will be automatically adjusted to your ad account’s time zone). If the current time is before 10 AM UTC, the ad sets will start immediately. If it's after 10 AM UTC, they will start at midnight.",
];
export const Tips2 = [
  "BUDGET_MODE_INFINITE - Бюджет кампании не ограничен (но ограничивается бюджетами адсетов). Суммарный бюджет кампании считается как сумма бюджетов всех адсетов. (минимальный бюджет 20$)",
];
export const Tips3 = [
  "Если в папке Google Drive есть изображения, добавьте один аудиофайл. В противном случае музыка будет выбрана случайным образом из аккаунта.",
];

export const Tips4 = [
  "В этом блоке необходимо указать ID кампании из TikTok и аккаунт, на котором она была создана.",
];

export const Tips5 = [
  "В данном блоке необходимо указать аккаунт, на котором будет создана копия кампании.",
];

export const budgetModes = ["BUDGET_MODE_INFINITE"];
export const typesAll = ["CBO", "ABO", "COST_CAP"];
export const stagesAll = ["L1", "L2"];
export const bidTypesAll = ["BID_TYPE_CUSTOM", "BID_TYPE_NO_BID"];

export const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";

export const API_URL_GET_DATA =
  "https://fb-campaign-creator-get-data-1049948018753.us-central1.run.app";

export const API_URL_CAMPAIGN_CREATION =
  "https://fb-campaign-creator-only-campaigns-tmgx45bhsa-uc.a.run.app";
