export async function addCityToName({ isCity, nameComment }) {
  if (isCity) {
    let newComment = `${nameComment} city`;
    return ` | ${newComment.trim()}`;
  }
  if (nameComment) {
    return ` | ${nameComment.trim()}`;
  }
  return "";
}

export const Tips = [
  "Выберите city, чтобы добавить слово 'city' в комментарий кампании. Комментарий отображается в конце имени кампании",
  "если комментария нет, будет просто 'city'",
  "если комментарий есть, 'city' добавится к нему: имя_кампании | комментарий city.",
];
