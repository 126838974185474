import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import OptionsCheckboxListRule from "../../dialog_checkboxes_rule_actions";

const DialogFormRuleCombined = ({
  openEditDialog,
  handleEditDialogClose,
  handleSave,
  selectedRow,
  userName,
  userRole,
  handleValueChange,
  tempValue1,
  tempValue2,
  tempValue3,
  tempValue4,
  tempValue5,
  tempValue6,
  tempAction1Value,
  tempNotifyOnly,
  tempActionOnly,
  Tips,
}) => {
  const hideInput = (value) => value && value.includes("NONE");

  return (
    <Dialog
      open={openEditDialog}
      onClose={handleEditDialogClose}
      maxWidth={false}
      fullWidth
      PaperProps={{
        style: {
          width: "70%",
          height: "70%",
        },
      }}
    >
      <DialogTitle>Edit Rule</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <pre>
            <b>
              {selectedRow ? (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {selectedRow.condition1 && (
                      <>
                        {selectedRow.condition1}
                        {!hideInput(tempValue1) && tempValue1 !== undefined && (
                          <TextField
                            value={tempValue1}
                            onChange={(e) => handleValueChange("setValue1", e.target.value)}
                            variant="standard"
                            size="small"
                            type="number"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                    {selectedRow.condition2 && (
                      <>
                        {selectedRow.condition2}
                        {!hideInput(tempValue2) && tempValue2 !== undefined && (
                          <TextField
                            value={tempValue2}
                            onChange={(e) => handleValueChange("setValue2", e.target.value)}
                            variant="standard"
                            size="small"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                    {selectedRow.condition3 && (
                      <>
                        {selectedRow.condition3}
                        {!hideInput(tempValue3) && tempValue3 !== undefined && (
                          <TextField
                            value={tempValue3}
                            onChange={(e) => handleValueChange("setValue3", e.target.value)}
                            variant="standard"
                            size="small"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {selectedRow.condition4}
                    {selectedRow.condition4 && (
                      <>
                        {selectedRow.condition4}
                        {!hideInput(tempValue4) && tempValue4 !== undefined && (
                          <TextField
                            value={tempValue4}
                            onChange={(e) => handleValueChange("setValue4", e.target.value)}
                            variant="standard"
                            size="small"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                    {selectedRow.condition5 && (
                      <>
                        {selectedRow.condition5}
                        {!hideInput(tempValue5) && tempValue5 !== undefined && (
                          <TextField
                            value={tempValue5}
                            onChange={(e) => handleValueChange("setValue5", e.target.value)}
                            variant="standard"
                            size="small"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <br />
                  {selectedRow.action1 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
                        alt="Red Arrow"
                        style={{ width: "24px", height: "24px", marginRight: "20px" }}
                      />
                      {!hideInput(tempAction1Value) && (
                        <TextField
                          value={tempAction1Value}
                          onChange={(e) => handleValueChange("setAction1Value", e.target.value)}
                          variant="standard"
                          size="small"
                          type="number"
                          style={{ width: "6%" }}
                        />
                      )}
                      {selectedRow.action2}
                    </div>
                  )}
                  <br />
                  <OptionsCheckboxListRule
                    userRole={userRole}
                    userName={userName}
                    tempNotifyOnly={tempNotifyOnly}
                    tempActionOnly={tempActionOnly}
                    userNotifyOnly={tempActionOnly}
                    userActionOnly={tempActionOnly}
                    handleValueChange={handleValueChange}
                  />
                </>
              ) : (
                <div>No data available</div>
              )}
            </b>
          </pre>
          <MDBox mb={1} mt={4}>
            <MDTypography variant="body3" color="text">
              Follow these tips to ensure correct ROI/Budget/Spend setup
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {Tips}
            </MDBox>
          </MDBox>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFormRuleCombined;

DialogFormRuleCombined.propTypes = {
  openEditDialog: PropTypes.any,
  handleEditDialogClose: PropTypes.any,
  handleSave: PropTypes.any,
  selectedRow: PropTypes.any,
  userRole: PropTypes.any,
  userName: PropTypes.any,
  handleValueChange: PropTypes.any,
  tempValue1: PropTypes.any,
  tempValue2: PropTypes.any,
  tempValue3: PropTypes.any,
  tempValue4: PropTypes.any,
  tempValue5: PropTypes.any,
  tempValue6: PropTypes.any,
  tempAction1Value: PropTypes.any,
  tempNotifyOnly: PropTypes.any,
  tempActionOnly: PropTypes.any,
  Tips: PropTypes.any,
};
