import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

const hideInput = (value) => value && value.includes("NONE");

export const generateCondition = (rule) => {
  return (
    <pre>
      <div>
        {rule.condition1}
        {!hideInput(rule.value1) && rule.value1}
        {rule.condition2}
        {!hideInput(rule.value2) && rule.value2}
        {rule.condition3}
        {!hideInput(rule.value3) && rule.value3}
      </div>
      <div>
        {rule.condition4}
        {!hideInput(rule.value4) && rule.value4}
        {rule.condition5}
        {!hideInput(rule.value5) && rule.value5}
      </div>
    </pre>
  );
};

export const generateAction = (rule) => {
  return (
    <pre>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
          alt="Red Arrow"
          style={{ width: "24px", height: "24px", marginRight: "20px" }}
        />
        {rule.action1}
        {!hideInput(rule.action_value1) && rule.action_value1}
        {rule.action2}
        {!hideInput(rule.action_value2) && rule.action_value2}
      </div>
    </pre>
  );
};

export const processRulesCombined = (
  rules,
  userRole,
  userName,
  handleToggle,
  setActivationHourProvider,
  setGlobalRuleStatus,
  handleEditClick
) => {
  const userEmail = localStorage.getItem("userEmail");
  const isAdmin = userRole === "admin";
  return rules.map((rule, index) => {
    const activationHour = rule["first_set_activation_hour"];
    setGlobalRuleStatus(rule["global_rule_status"]);

    // Set activation hour Provider
    setActivationHourProvider(activationHour);

    const conditionContent = generateCondition(rule);
    const actionContent = generateAction(rule);

    return {
      rule_name: (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={rule.rule_status}
                onChange={() => {
                  const currentStatus = rule.rule_status;
                  handleToggle(rule.rule_name, "rule_status", currentStatus);
                }}
                disabled={userRole !== "admin"}
              />
            }
            label={`${rule.rule_name}`}
          />
        </div>
      ),
      condition3: rule.condition3,
      condition4: rule.condition4,
      condition5: rule.condition5,
      condition6: rule.condition6,
      action2: rule.action2,
      condition: conditionContent,
      action: actionContent,
      edit: (
        <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
          <EditIcon />
        </IconButton>
      ),
    };
  });
};
