import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "CUSTOM_COMPONENTS/custom_dropdown";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomInputField from "CUSTOM_COMPONENTS/custom_input_field";
import CustomInputFieldMultiline from "CUSTOM_COMPONENTS/custom_input_field_multiline";
import CustomNumericInputField from "CUSTOM_COMPONENTS/custom_numeric_input_field";
import { emailsNames, languagesAll, Tips_for_all } from "../constants";
import { renderTips } from "../../../CUSTOM_COMPONENTS/render_TIPS";

import {
  SectionHeader,
  ttGeoAll,
  Tips,
  Tips2,
  Tips3,
  budgetModes,
  typesAll,
  stagesAll,
  bidTypesAll,
  API_URL,
  API_URL_CAMPAIGN_CREATION,
} from "../tt_constants";
import MDInput from "../../../components/MDInput";
import Button from "@mui/material/Button";
import CustomDropdownInput from "../../../CUSTOM_COMPONENTS/custom_dropdown_input";

const srcDefault = "https://www.svgrepo.com/show/489670/highlighter.svg";

function CreateTTCampaignNEW() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [selectedAccount, setSelectedAccount] = useState("");
  const [accountsAll, setAccountsAll] = useState([]);
  const [identitiesAll, setIdentitiesAll] = useState([]);
  const [categoriesAll, setCategoriesAll] = useState([]);
  const [selectedIdentity, setSelectedIdentity] = useState("");

  const [inputGoogleDriveFolder, setInputGoogleDriveFolder] = useState("");
  const [inputFbCampaignCategory, setInputFbCampaignCategory] = useState("");
  const [selectedBudgetMode, setSelectedBudgetMode] = useState("BUDGET_MODE_INFINITE");
  const [selectedType, setSelectedType] = useState("COST_CAP");
  const [selectedStage, setSelectedStage] = useState("L1");
  const [inputBudget, setInputBudget] = useState(20);
  const [inputBid, setInputBid] = useState(5);
  const [inputAdsPerAdGroup, setInputAdsPerAdGroup] = useState(1);
  const [inputAdText, setInputAdText] = useState("");
  const [inputLinkUrl, setInputLinkUrl] = useState("");
  const [selectedGeo, setSelectedGeo] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedBidType, setSelectedBidType] = useState("BID_TYPE_CUSTOM");
  const [inputComment, setInputComment] = useState("");

  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const defaultDate = tomorrow.toISOString().split("T")[0]; // 'YYYY-MM-DD' format

  const [date, setDate] = useState(defaultDate);
  const [time, setTime] = useState("00:00:00");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accountsResponse = await fetch(`${API_URL}/get_tt_accounts_all/`);
        const accountsData = await accountsResponse.json();
        setAccountsAll(accountsData);

        const categoryResponse = await fetch(`${API_URL}/get_categories_all/`);
        const categoriesData = await categoryResponse.json();
        setCategoriesAll(categoriesData);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchIdentities = async () => {
      if (!selectedAccount) return;

      setIsLoading(true);
      try {
        let advertiserId = selectedAccount.split(": ").at(-1).trim();
        console.log(selectedAccount);
        console.log(advertiserId);
        const response = await fetch(`${API_URL}/get_identity_for_account/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ advertiser_id: advertiserId }),
        });

        const data = await response.json();
        setIdentitiesAll(data);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchIdentities();
  }, [selectedAccount]);

  const handleCreateCampaign = () => {
    if (
      !selectedAccount ||
      !selectedBudgetMode ||
      !inputFbCampaignCategory ||
      !selectedType ||
      !selectedStage ||
      !inputBudget ||
      !selectedGeo ||
      !selectedLanguage ||
      !selectedAccount ||
      !selectedBidType ||
      !selectedIdentity ||
      !inputAdText ||
      !inputLinkUrl
    ) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL_CAMPAIGN_CREATION}/tt-new-campaign-create/`, {
      // fetch(`${API_URL_CAMPAIGN_CREATION}/campaign-create-test-response/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          schedule_date: date,
          schedule_time: time,
          google_drive_folder: inputGoogleDriveFolder,
          ads_per_adset: inputAdsPerAdGroup,
          budget_mode: selectedBudgetMode,
          campaign__category: inputFbCampaignCategory,
          d___campaign_type: selectedType,
          d___campaign_stage: selectedStage,
          budget: inputBudget,
          geo: selectedGeo,
          language: selectedLanguage,
          comment: inputComment,
          advertiser: selectedAccount,
          bid_type: selectedBidType,
          conversion_bid_price: inputBid ? inputBid : 0.0,
          identity_name_id: selectedIdentity,
          ad_text: inputAdText,
          landing_page_url: inputLinkUrl,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.data || `HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Campaign successfully created!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleCreateCampaign}>
            Create campaign
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={1} display="flex" alignItems="center">
                <img
                  src="https://www.svgrepo.com/show/489658/to-chat-with.svg"
                  alt="Image"
                  style={{ width: "45px", height: "45px" }}
                />
                <MDTypography variant="h5">&nbsp;&nbsp;Create TT campaign NEW</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={3}>
                  <SectionHeader text="Campaign Name Setup" iconSrc={srcDefault} />
                  <Grid item xs={12} md={8}>
                    <CustomDropdown
                      selectedCategory={selectedAccount}
                      setSelectedCategory={setSelectedAccount}
                      categories={accountsAll}
                      category_name={"TT - AD account"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomDropdown
                      selectedCategory={selectedType}
                      setSelectedCategory={setSelectedType}
                      categories={typesAll}
                      category_name={"Campaign type"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomDropdown
                      selectedCategory={selectedStage}
                      setSelectedCategory={setSelectedStage}
                      categories={stagesAll}
                      category_name={"Campaign stage"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomDropdown
                      selectedCategory={selectedGeo}
                      setSelectedCategory={setSelectedGeo}
                      categories={ttGeoAll}
                      category_name={"TT GEO"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomDropdown
                      selectedCategory={selectedLanguage}
                      setSelectedCategory={setSelectedLanguage}
                      categories={languagesAll}
                      category_name={"Language"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <CustomDropdownInput
                      selectedValue={inputFbCampaignCategory}
                      setSelectedValue={setInputFbCampaignCategory}
                      options={categoriesAll}
                      label={"Campaign Category"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomInputField
                      inputValue={inputComment}
                      setInputValue={setInputComment}
                      label={"Comment"}
                    />
                  </Grid>

                  <SectionHeader text="Fields to setup TT campaign" iconSrc={srcDefault} />
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        flexWrap="wrap"
                        mt={5}
                        ml={6}
                      >
                        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                          {renderTips(Tips3)}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </MDBox>
                  <Grid item xs={12} md={12}>
                    <CustomInputField
                      inputValue={inputGoogleDriveFolder}
                      setInputValue={setInputGoogleDriveFolder}
                      label={"Google drive Folder name or link"}
                      required={true}
                    />
                  </Grid>
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        flexWrap="wrap"
                        mt={5}
                        ml={6}
                      >
                        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                          {renderTips(Tips2)}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </MDBox>
                  <Grid item xs={12} md={5}>
                    <CustomDropdown
                      selectedCategory={selectedIdentity}
                      setSelectedCategory={setSelectedIdentity}
                      categories={identitiesAll}
                      category_name={"Identity ID (TT page)"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomDropdown
                      selectedCategory={selectedBudgetMode}
                      setSelectedCategory={setSelectedBudgetMode}
                      categories={budgetModes}
                      category_name={"Budget Mode"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <CustomDropdown
                      selectedCategory={selectedBidType}
                      setSelectedCategory={setSelectedBidType}
                      categories={bidTypesAll}
                      category_name={"BID TYPE"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" color="green">
                      Max length: 100 symbols
                    </Typography>
                    <CustomInputField
                      inputValue={inputAdText}
                      setInputValue={setInputAdText}
                      label={"Ad text"}
                      required={true}
                      maxLength={99}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography variant="caption" color="green">
                      Default: 1 ad per adgroup
                    </Typography>
                    <CustomNumericInputField
                      inputValue={inputAdsPerAdGroup}
                      setInputValue={setInputAdsPerAdGroup}
                      label={"Ads per Adgroup"}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography variant="caption" color="green">
                      Min: $20 for Ad group
                    </Typography>
                    <CustomNumericInputField
                      inputValue={inputBudget}
                      setInputValue={setInputBudget}
                      label={"Daily budget (in $)"}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography variant="caption" color="green">
                      IN $
                    </Typography>
                    <CustomNumericInputField
                      inputValue={inputBid}
                      setInputValue={setInputBid}
                      label={"Conversion Bid Price"}
                      allowFloat={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CustomInputFieldMultiline
                      inputValue={inputLinkUrl}
                      setInputValue={setInputLinkUrl}
                      label={"Landing Page URL"}
                      required={true}
                    />
                  </Grid>
                  <SectionHeader
                    text="Schedule campaign start based on account timezone (or click ⛳️ to activate
                      immediately)"
                    iconSrc={srcDefault}
                  />
                  <Grid item xs={12} md={12} container alignItems="center">
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 5 }}>
                      <MDInput type="date" value={date} onChange={handleDateChange} />
                      <MDInput type="time" value={time} onChange={handleTimeChange} />
                      <Button
                        onClick={() => {
                          setDate("");
                          setTime("");
                        }}
                        variant="contained"
                        color="white"
                      >
                        ⛳️ START NOW
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateTTCampaignNEW;
