import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  getCurrentTimeDifference,
  getUtcTimeDifference,
} from "../../../CUSTOM_COMPONENTS/time_functions";

const userEmail = localStorage.getItem("userEmail");
const userRole = localStorage.getItem("userRole");
export const API_URL = "https://dashboard-1049948018753.us-central1.run.app";

export const userNames = {
  "Artemyusi@gmail.com": "USER1",
  "Vlad0986613794@gmail.com": "USER2",
  "nuzhnyias@gmail.com": "USER3",
  "andreylipovskiy0@gmail.com": "USER4",
  "mcerneva2@gmail.com": "USER5",
  "Hembarskyi_user@gmail.com": "USER6",
};

export const generateCondition = (rule, userRole, userName) => {
  if (userRole === "admin" || userRole === "user") {
    return (
      <pre>
        <div>
          {rule.condition1}
          {rule.value1}
          {rule.condition2}
          {rule.value2}
          {rule.condition3}
          {rule.value3}
        </div>
        <div>
          {rule.condition4}
          {rule.value4}
          {rule.condition5}
          {rule.value5}
          {rule.condition6}
          {rule.value6}
          {rule.condition7}
        </div>
      </pre>
    );
  } else {
    return (
      <pre>
        <div>
          {rule.condition1}
          {rule[`value1_${userName}`]}
          {rule.condition2}
          {rule[`value2_${userName}`]}
          {rule.condition3}
          {rule[`value3_${userName}`]}
        </div>
        <div>
          {rule.condition4}
          {rule[`value4_${userName}`]}
          {rule.condition5}
          {rule[`value5_${userName}`]}
          {rule.condition6}
          {rule[`value6_${userName}`]}
          {rule.condition7}
        </div>
      </pre>
    );
  }
};

export const generateAction = (rule, userRole, userName) => {
  if (userRole === "admin") {
    return (
      <pre>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
            alt="Red Arrow"
            style={{ width: "24px", height: "24px", marginRight: "20px" }}
          />
          {rule.action1}
          {rule.action1_value}
          {rule.action2}
          {rule.action1_value}
        </div>
      </pre>
    );
  } else {
    return (
      <pre>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
            alt="Red Arrow"
            style={{ width: "24px", height: "24px", marginRight: "20px" }}
          />
          {rule.action1}
          {rule[`action1_value_${userName}`]}
          {rule.action2}
          {rule[`action1_value_${userName}`]}
        </div>
      </pre>
    );
  }
};

export const processRules = (
  rules,
  userRole,
  userName,
  handleToggle,
  setActivationHourProvider,
  setActivationHourEstonia,
  setRunInterval,
  setGlobalRuleStatus,
  handleEditClick,
  provider
) => {
  const userEmail = localStorage.getItem("userEmail");
  const isAdmin = userRole === "admin";
  return rules.map((rule, index) => {
    const activationHourField = ["sl_1", "sl_2", "sl_3", "sl_4"].includes(rule.rule_name)
      ? "first_set_activation_hour"
      : "second_set_activation_hour";

    const activationHour = rule[activationHourField];

    // Set activation hour Provider
    setActivationHourProvider(activationHour);

    const hourInt = parseInt(activationHour, 10);
    let activationHourEst = null;

    // Set Estonian activation hour
    if (!isNaN(hourInt)) {
      const timeDifference =
        provider === "S1" ? getUtcTimeDifference() : getCurrentTimeDifference();
      activationHourEst = (hourInt + timeDifference) % 24;
      setActivationHourEstonia(activationHourEst);
    }

    // Set run interval
    const setInterval = isAdmin ? rule["set_run_interval"] : rule[`set_run_interval_${userName}`];
    setRunInterval(parseInt(setInterval));

    // Set global rule status
    const globalStatus = isAdmin
      ? rule["global_rule_status"]
      : rule[`global_rule_status_${userName}`];
    setGlobalRuleStatus(globalStatus);

    const conditionContent = generateCondition(rule, userRole, userName);
    const actionContent = generateAction(rule, userRole, userName);

    return {
      rule_name: (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={rule.rule_status}
                onChange={() => {
                  const currentStatus = rule.rule_status;
                  handleToggle(rule.rule_name, "rule_status", currentStatus);
                }}
                disabled={userRole !== "admin"}
              />
            }
            label={`${rule.rule_name}`}
          />
        </div>
      ),
      condition3: rule.condition3,
      condition4: rule.condition4,
      condition5: rule.condition5,
      condition6: rule.condition6,
      action2: rule.action2,
      condition: conditionContent,
      action: actionContent,
      edit: (
        <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
          <EditIcon />
        </IconButton>
      ),
    };
  });
};

export const budgetROITips = [
  "The symbols < and > exclude the number they compare to, so the boundary is not included.",
  "The symbols <= and >= include the number they compare to, so the boundary is included.",
  "For negative values, specify the dash symbol without spaces(ex. -25).",
];

export const ruleTips3 = ["Rules s1_sl_5 and sl_6 apply from 00:00 to 20:00 provider time."];
export const ruleTips4 = ["Rules s1_sl_5 and sl_6 apply from 00:00 to 20:00 provider time."];
