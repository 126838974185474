import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "CUSTOM_COMPONENTS/custom_dropdown";
import CustomInputField from "CUSTOM_COMPONENTS/custom_input_field";
import CustomInputFieldMultiline from "CUSTOM_COMPONENTS/custom_input_field_multiline";
import { renderTips } from "../../../CUSTOM_COMPONENTS/render_TIPS";
import { SectionHeader, API_URL, API_URL_CAMPAIGN_CREATION } from "../tt_constants";
import { Tips1, Tips2 } from "./constants";
import MDInput from "../../../components/MDInput";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const iconImg = "https://www.svgrepo.com/show/421829/office-pin-pushpin.svg";

function CopyCampaignFBFromFB() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [selectedAccount, setSelectedAccount] = useState("");
  const [accountsAll, setAccountsAll] = useState([]);

  const [inputFBAdsetId, setInputFBAdsetId] = useState("");
  const [inputLinkUrl, setInputLinkUrl] = useState("");
  const [inputGoogleDriveFolder, setInputGoogleDriveFolder] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const defaultDate = tomorrow.toISOString().split("T")[0]; // 'YYYY-MM-DD' format

  const [date, setDate] = useState(defaultDate);
  const [time, setTime] = useState("00:00:00");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accountsResponse = await fetch(`${API_URL}/get_accounts_all/`);
        const accountsData = await accountsResponse.json();
        setAccountsAll(accountsData);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCreateCampaign = () => {
    if (!inputFBAdsetId || !inputGoogleDriveFolder || !selectedAccount) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL_CAMPAIGN_CREATION}/fb-campaign-copy-settings/`, {
      // fetch(`${API_URL_CAMPAIGN_CREATION}/campaign-create-test-response/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          schedule_date: date,
          schedule_time: time,
          fb_adset_id: inputFBAdsetId,
          advertiser: selectedAccount,
          landing_page_url: inputLinkUrl ? inputLinkUrl : null,
          google_drive_folder: inputGoogleDriveFolder,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.data || `HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Campaign successfully created!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleCreateCampaign}>
            Create campaign
          </button>
        </div>
      </div>
    );
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={1} display="flex" alignItems="center">
                <img
                  src="https://www.svgrepo.com/show/421835/market-news-newspaper.svg"
                  alt="Image"
                  style={{ width: "45px", height: "45px" }}
                />
                <MDTypography variant="h5">&nbsp;&nbsp;Scale FB creative</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={3}>
                  <SectionHeader text="FB Parent Adset ID" iconSrc={iconImg} />
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={3}>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        flexWrap="wrap"
                        mt={5}
                        ml={6}
                      >
                        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                          {renderTips(Tips1)}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CustomInputField
                      inputValue={inputFBAdsetId}
                      setInputValue={setInputFBAdsetId}
                      label={"FB Adgroup ID"}
                      required={true}
                    />
                  </Grid>
                  <SectionHeader text="Fields to setup FB campaign" iconSrc={iconImg} />
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        flexWrap="wrap"
                        mt={5}
                        ml={6}
                      >
                        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                          {renderTips(Tips2)}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </MDBox>
                  <Grid item xs={12} md={7}>
                    <CustomDropdown
                      selectedCategory={selectedAccount}
                      setSelectedCategory={setSelectedAccount}
                      categories={accountsAll}
                      category_name={"FB account"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <CustomInputField
                      inputValue={inputGoogleDriveFolder}
                      setInputValue={setInputGoogleDriveFolder}
                      label={"Google Drive Folder"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <CustomInputFieldMultiline
                      inputValue={inputLinkUrl}
                      setInputValue={setInputLinkUrl}
                      label={"Landing Page URL"}
                    />
                  </Grid>
                  <SectionHeader
                    text="Schedule campaign start based on account timezone (or click ⛳️ to activate
                      immediately)"
                    iconSrc={iconImg}
                  />
                  <Grid item xs={12} md={12} container alignItems="center">
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 5 }}>
                      <MDInput type="date" value={date} onChange={handleDateChange} />
                      <MDInput type="time" value={time} onChange={handleTimeChange} />
                      <Button
                        onClick={() => {
                          setDate("");
                          setTime("");
                        }}
                        variant="contained"
                        color="white"
                      >
                        ⛳️ START NOW
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CopyCampaignFBFromFB;
