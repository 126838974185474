import React, { useEffect } from "react";
import { Box, Typography, FormControl, InputLabel, Input, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

const CustomInputField = ({ inputValue, setInputValue, label, required, maxLength }) => {
  const [touched, setTouched] = React.useState(false);

  useEffect(() => {
    if (required) {
      setTouched(true);
    }
  }, [required]);

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mb={2}
      sx={{
        gap: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <FormControl
          variant="standard"
          sx={{ width: "100%" }}
          required={required}
          error={required && touched && !inputValue}
        >
          <InputLabel sx={{ mb: 1, color: "grey" }}>{label}</InputLabel>
          <Input
            value={inputValue}
            onBlur={handleBlur}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{ width: "100%" }}
            inputProps={{ maxLength }}
          />
          {required && touched && !inputValue && (
            <FormHelperText>Please enter {label.toLowerCase()}</FormHelperText>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

CustomInputField.propTypes = {
  inputValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
};

CustomInputField.defaultProps = {
  maxLength: null,
};

export default CustomInputField;
