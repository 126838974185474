import LogOut from "layouts/authentication/logout";

import CreateCampaign from "layouts2/CAMPAIGNS_ACTIONS/create_campaign";
import CreateReport from "layouts/reports/create_report";

// @mui icons
import Icon from "@mui/material/Icon";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import GridViewIcon from "@mui/icons-material/GridView";
import AddTaskIcon from "@mui/icons-material/AddTask";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import setIsLoggedIn from "./layouts/authentication/sign-in/basic/index";
import Calendar from "layouts/applications/calendar";
import Cover from "./layouts/authentication/sign-in/cover";
import AntiScam from "./layouts/dashboards/anti_scam";
import CampaignsALL from "./layouts2/CAMPAIGNS_ACTIONS/campaigns_all";
import TablesHistorical from "./layouts2/REPORT_TABLES/historical_tables";
import TablesDailyDa from "./layouts2/REPORT_TABLES/daily_tables_da";
import TablesHourly from "./layouts2/REPORT_TABLES/hourly_tables";
import TablesDailyS1 from "./layouts2/REPORT_TABLES/daily_tables_s1";
import SelectedCategories from "./layouts/task_management/selected_categories";
import LogsActivity from "./layouts/task_management/logs_activity";
import ParsedFbLinks from "./layouts/task_management/parsed_fb_links";
import KWAnalytics from "./layouts2/REPORT_TABLES/kw_analytics";
import CreateKWLIST from "./layouts2/GENERATE_LINK/kw_actions/create_kw_lists";
import UpdateCampaign from "./layouts2/CAMPAIGNS_ACTIONS/update_campaign";
import LogsActivityAutorules from "./layouts2/LOGS/logs_rules_start_end";
import GavelIcon from "@mui/icons-material/Gavel";
import ScaleCampaign from "./layouts2/CAMPAIGNS_ACTIONS/create_campaign/create_campaign_scale";
import GenerateLink from "./layouts2/GENERATE_LINK/generate_link";
import GenerateLinkS1 from "./layouts2/GENERATE_LINK/generate_link_s1";
import GenerateLinkTonic from "./layouts2/GENERATE_LINK/generate_link_tonic";
import StopLoss1_2024_12_09 from "./layouts2/RULES/stop_loss_1_ALL/stop_loss_1_2024_12_09/rule_main";
import StopLossS1_2024_12_09 from "./layouts2/RULES/stop_loss_1_ALL/stop_loss_s1_2024_12_09/rule_main";
import CopyCampaign from "./layouts2/CAMPAIGNS_ACTIONS/copy_campaign/copy_campaign_form";
import GenerateLinkS1TikTok from "./layouts2/GENERATE_LINK/generate_link_s1_tiktok";
import GenerateKW from "./layouts2/CAMPAIGNS_ACTIONS/generate_keywords/generate_kw";
import GenerateLinkDATikTok from "./layouts2/GENERATE_LINK/generate_link_da_tiktok";
import CreateCampaignTTFromFB from "./layouts2/CAMPAIGNS_ACTIONS/create_tt_campaign_from_fb";
import StopLossCombined_2025_02_27 from "./layouts2/RULES/stop_loss_1_ALL/stop_loss_combined_2025_02_27/rule_main";
import CreateTTCampaignNEW from "./layouts2/CAMPAIGNS_ACTIONS/create_tt_campaign_new";
import CreateCampaignTTFromTT from "./layouts2/CAMPAIGNS_ACTIONS/copy_tt_campaign";
import CopyCampaignTTFromTT from "./layouts2/CAMPAIGNS_ACTIONS/copy_tt_campaign_settings";
import CopyCampaignFBFromFB from "./layouts2/CAMPAIGNS_ACTIONS/copy_fb_campaign_settings";
import StopLossCombined_TT_2025_03_12 from "./layouts2/RULES/stop_loss_1_ALL/stop_loss_combined_TT_2025_03_12/rule_main";
// My constants
const userEmail = localStorage.getItem("userEmail");

export const getUserRoutes = (userEmail, userRole) => {
  const baseRoutes = [
    {
      type: "collapse",
      name: userEmail,
      key: "user",
      icon: <Icon fontSize="large">manage_accounts</Icon>,
      collapse: [
        {
          name: "Logout",
          key: "logout",
          route: "/authentication/logout",
          component: <LogOut setIsLoggedIn={setIsLoggedIn} />,
        },
        {
          name: "Calendar",
          key: "calendar",
          route: "/applications/calendar",
          component: <Calendar userEmail={userEmail} />,
        },
      ],
    },
    { type: "divider", key: "divider-0" },
    { type: "title", title: "Pages", key: "title-pages" },
    {
      type: "collapse",
      name: "FB management",
      key: "campaigns",
      icon: <Icon fontSize="medium">assignment</Icon>,
      collapse: [
        {
          name: "Create campaign",
          key: "create_campaign",
          route: "/campaigns/create_campaign",
          component: <CreateCampaign />,
        },
        {
          name: "Update campaign",
          key: "update_campaign",
          route: "/campaigns/update_campaign",
          component: <UpdateCampaign />,
        },
        {
          name: "Scale campaign",
          key: "scale_campaign",
          route: "/campaigns/scale_campaign",
          component: <ScaleCampaign />,
        },
        {
          name: "Copy campaign",
          key: "copy_campaign",
          route: "/campaigns/copy_campaign",
          component: <CopyCampaign />,
        },
        {
          name: "Scale FB creative",
          key: "scale_fb_creative",
          route: "/campaigns/scale_fb_creative",
          component: <CopyCampaignFBFromFB />,
        },
        {
          name: "Actions logs",
          key: "logs_activity",
          route: "/manage_logs/logs_activity",
          component: <LogsActivity />,
        },
      ],
    },
    {
      type: "collapse",
      name: "TT management",
      key: "TT campaigns",
      icon: <Icon fontSize="medium">assignment</Icon>,
      collapse: [
        {
          name: "Create TT (from FB)",
          key: "create_tt_campaign",
          route: "/campaigns/create_tt_campaign_from_fb_campaign",
          component: <CreateCampaignTTFromFB />,
        },
        {
          name: "Create TT (NEW)",
          key: "create_tt_campaign_new",
          route: "/campaigns/create_tt_campaign_new",
          component: <CreateTTCampaignNEW />,
        },
        {
          name: "Copy TT campaign",
          key: "copy_tt_campaign",
          route: "/campaigns/copy_tt_campaign",
          component: <CreateCampaignTTFromTT />,
        },
        {
          name: "Scale TT Creative",
          key: "scale_tt_creative",
          route: "/campaigns/scale_tt_creative",
          component: <CopyCampaignTTFromTT />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Links management",
      key: "links",
      icon: <Icon fontSize="medium">assignment</Icon>,
      collapse: [
        {
          name: "Generate Link DA",
          key: "generate_link_DA",
          route: "/campaigns/generate_link_DA",
          component: <GenerateLink />,
        },
        {
          name: "Generate Link S1",
          key: "generate_link_S1",
          route: "/campaigns/generate_link_S1",
          component: <GenerateLinkS1 />,
        },
        {
          name: "Generate Link S1 TikTok",
          key: "generate_link_S1_TikTok",
          route: "/campaigns/generate_link_S1_TikTok",
          component: <GenerateLinkS1TikTok />,
        },
        {
          name: "Generate Link DA TikTok",
          key: "generate_link_DA_TikTok",
          route: "/campaigns/generate_link_DA_TikTok",
          component: <GenerateLinkDATikTok />,
        },
        {
          name: "Generate Link Tonic",
          key: "generate_link_Tonic",
          route: "/campaigns/generate_link_Tonic",
          component: <GenerateLinkTonic />,
        },
        {
          name: "Actions logs",
          key: "logs_activity",
          route: "/manage_logs/logs_activity",
          component: <LogsActivity />,
        },
      ],
    },
    {
      type: "collapse",
      name: "AutoRules",
      key: "auto_rules",
      icon: <GavelIcon />,
      collapse: [
        // {
        //   name: "Logs AutoRules",
        //   key: "logs_activity_auto_rules",
        //   route: "/manage_logs/logs_activity_auto_rules",
        //   component: <LogsActivityAutorules />,
        // },
        {
          name: "Stop_loss_1 DA",
          key: "stop_loss_1_DA_2024_12_09",
          route: "/rules/stop_loss_1_DA_2024_12_09",
          component: <StopLoss1_2024_12_09 />,
        },
        {
          name: "Stop_loss_1 S1",
          key: "stop_loss_1_S1_2024_12_09",
          route: "/rules/stop_loss_1_S1_2024_12_09",
          component: <StopLossS1_2024_12_09 />,
        },
        {
          name: "Stop_loss COMB",
          key: "stop_loss_combined_2025_02_27",
          route: "/rules/stop_loss_combined_2025_02_27",
          component: <StopLossCombined_2025_02_27 />,
        },
        {
          name: "Stop_loss TT COMB",
          key: "stop_loss_combined_TT_2025_03_12",
          route: "/rules/stop_loss_combined_TT_2025_03_12",
          component: <StopLossCombined_TT_2025_03_12 />,
        },
        // {
        //   name: "Stop_loss_1 v.2",
        //   key: "stop_loss_1_v2",
        //   route: "/rules/stop_loss_1_v2",
        //   component: <StopLoss1_v2 />,
        // },
        // {
        //   name: "Stop_loss_2",
        //   key: "stop_loss_2",
        //   route: "/rules/stop_loss_2",
        //   component: <StopLoss2 />,
        // },
        // {
        //   name: "S-24 V1",
        //   key: "s24_v_1",
        //   route: "/rules/S24_v_1",
        //   component: <S24V1_1 />,
        // },
        // {
        //   name: "S-24 V2",
        //   key: "s24_v_2",
        //   route: "/rules/S24_v_2",
        //   component: <S24V2_1 />,
        // },
        // {
        //   name: "S-24 V3",
        //   key: "s24_v_3",
        //   route: "/rules/S24_v_3",
        //   component: <S24V3_1 />,
        // },
        // {
        //   name: "Scale",
        //   key: "Scale",
        //   route: "/rules/scale",
        //   component: <ScaleRule />,
        // },
        // {
        //   name: "Ads",
        //   key: "Ads",
        //   route: "/rules/ads",
        //   component: <AdsRule />,
        // },
      ],
    },
    {
      type: "collapse",
      name: "KW management",
      key: "kw_management",
      icon: <VpnKeyIcon />,
      collapse: [
        {
          name: "Generate KW",
          key: "generate KW",
          route: "/campaigns/generate_kw",
          component: <GenerateKW />,
        },
        {
          name: "KWAnalytics",
          key: "KW_Analytics",
          route: "/kw_management/kw_analytics",
          component: <KWAnalytics />,
        },
        {
          name: "KW settings",
          key: "create_kw_lists",
          route: "/kw_management/create_kw_lists",
          component: <CreateKWLIST />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Dashboards",
      key: "dashboards",
      icon: <GridViewIcon />,
      collapse: [
        {
          name: "Historical tables",
          key: "historical_tables",
          route: "/dashboards/historical_tables",
          component: <TablesHistorical />,
        },
        {
          name: "Daily tables DA",
          key: "daily_tables_da",
          route: "/dashboards/daily_tables_da",
          component: <TablesDailyDa />,
        },
        {
          name: "Daily tables S1",
          key: "daily_tables_s1",
          route: "/dashboards/daily_tables_s1",
          component: <TablesDailyS1 />,
        },
        {
          name: "Hourly tables",
          key: "hourly_tables",
          route: "/dashboards/hourly_tables",
          component: <TablesHourly />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Task management",
      key: "manage_tasks",
      icon: <AddTaskIcon />,
      collapse: [
        {
          name: "Partners Research",
          key: "partners_research",
          route: "/task_management/partners_research",
          component: <SelectedCategories />,
        },
        // {
        //   name: "Task manage 2",
        //   key: "selected_categories_analysis",
        //   route: "/task_management/selected_categories_analysis",
        //   component: <SelectedCategoriesAnalysis />,
        // },
        {
          name: "Own Research",
          key: "own_research",
          route: "/task_management/own_research",
          component: <ParsedFbLinks />,
        },
      ],
    },
  ];

  if (userRole === "admin") {
    baseRoutes.push(
      { type: "divider", key: "divider-1" },
      { type: "title", title: "Admin only", key: "title-adm" },
      {
        type: "collapse",
        name: "Anti Scam",
        key: "anti_scam",
        icon: <Icon fontSize="medium">rule</Icon>,
        collapse: [
          {
            name: "Anti Scam",
            key: "auto_rules",
            route: "/auto_rules/anti_scam",
            component: <AntiScam />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Manage campaigns",
        key: "manage_campaigns",
        icon: <AddModeratorIcon />,
        collapse: [
          {
            name: "All campaigns",
            key: "campaigns_all",
            route: "/manage_campaigns/campaigns_all",
            component: <CampaignsALL />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Reports",
        key: "reports",
        icon: <Icon fontSize="medium">grading</Icon>,
        collapse: [
          {
            name: "Create report",
            key: "create_report",
            route: "/reports/create_report",
            component: <CreateReport />,
          },
        ],
      },
      {
        type: "collapse",
        name: "IN PROGRESS",
        key: "in_progress",
        icon: <VpnKeyIcon />,
        collapse: [],
      }
    );
  }

  if (userEmail === "xxxuko@gmail.com") {
    baseRoutes.push(
      { type: "divider", key: "divider-1" },
      { type: "title", title: "Me only", key: "title-adm" },
      {
        type: "collapse",
        name: "Users settings",
        key: "user-settings",
        icon: <Icon fontSize="medium">rule</Icon>,
        collapse: [
          {
            name: "Add new user",
            key: "sign-up",
            route: "/authentication/sign-up/cover",
            component: <Cover />,
          },
          // {
          //   name: "All users",
          //   key: "all",
          //   route: "/users/all",
          //   component: <Cover />,
          // },
        ],
      }
    );
  }
  return baseRoutes;
};
