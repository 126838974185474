import { Checkbox, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";

const CustomCheckbox = ({ selectedValue, setSelectedValue, label }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={selectedValue} onChange={handleChange} color="primary" />}
      label={label}
    />
  );
};

CustomCheckbox.propTypes = {
  selectedValue: PropTypes.bool.isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomCheckbox;
