import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import PropTypes from "prop-types";

const ProviderToggle = ({ selectedProvider, setSelectedProvider }) => {
  const handleChange = (_, newProvider) => {
    if (newProvider !== null) {
      setSelectedProvider(newProvider);
    }
  };

  return (
    <ToggleButtonGroup
      value={selectedProvider}
      exclusive
      onChange={handleChange}
      aria-label="Provider selection"
      sx={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <ToggleButton
        value="DA"
        size="large"
        sx={{ flex: 1, fontSize: "1.2rem", padding: "10px 20px" }}
      >
        DA
      </ToggleButton>
      <ToggleButton
        value="S1"
        size="large"
        sx={{ flex: 1, fontSize: "1.2rem", padding: "10px 20px" }}
      >
        S1
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

ProviderToggle.propTypes = {
  selectedProvider: PropTypes.string.isRequired,
  setSelectedProvider: PropTypes.func.isRequired,
};

export default ProviderToggle;
